/* eslint-disable no-undef */
import React from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "primereact/button";

import Latest from "./home/latest";
import MeetUs from "./home/meet-is";

import "../assets/css/home.css";

import left from "../assets/img/home.img/left.png";
import right from "../assets/img/home.img/right.jpg";
import HowTo from "../assets/img/home.img/howto.jpg";
import Measure from "../assets/img/home.img/measure.webp";
import Quality from "../assets/img/home.img/quality.webp";
import Innovative from "../assets/img/home.img/innovative.jpg";

import Testimonials from "./reviews";

import { useTranslation } from "../language-maps/use-translation";

const Home = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <div style={{ backgroundColor: "#fff" }}>
      <div style={{ backgroundColor: "#fff" }} className="lg:pt-5 pt-7 pb-4">
        <div className="content-wrapper landing-wrapper">
          <img
            className="circle-image"
            src={require("../assets/img/home.img/circle.png")}
            alt="home image 1"
          />
          <h1
            style={{ fontSize: "9rem" }}
            className="text-primary font-bold mb-3 curtains-header"
          >
            Curtains
          </h1>
          <img
            className="home-image-1"
            src={require("../assets/img/home.img/home-image-1.jpg")}
            alt="home image 1"
          />
          <img
            className="home-image-3 hide-on-mobile"
            src={require("../assets/img/home.img/home-image-3.jpg")}
            alt="home image 3"
          />
          <img
            className="home-image-2"
            src={require("../assets/img/home.img/home-image-2.jpg")}
            alt="home image 2"
          />
          <div
            style={{
              width: "700px",
              marginTop: "-400px",
              marginLeft: "280px",
            }}
            className="flex flex-column align-items-center defines-header"
          >
            <h1
              className="defines-text"
              style={{ fontSize: "9rem", zIndex: 1 }}
            >
              That
            </h1>
            <h1 className="defines-text" style={{ fontSize: "9rem" }}>
              Define Style
            </h1>
          </div>
          <Button
            label={t("Explore Our Range")}
            icon="pi pi-angle-right"
            className="explore-button"
            onClick={() => navigate("/gallery")}
            text
          />
        </div>
        <div className="content-wrapper lg:pt-8">
          <div className="grid lg:mt-7 l02g:px-8 frame-designs">
            <div className="col-3 flex align-items-center justify-content-center home-frame-wraper">
              <div>
                <img
                  className="home-frames"
                  src={require("../assets/img/home.img/Layer-1.jpg")}
                  alt="home frame 1"
                />
              </div>
              <div className="text-center frame-paras">
                <p>
                  {t("Home and")}
                  <br />
                  {t("Office Solutions")}
                </p>
              </div>
            </div>
            <div className="col-3 flex align-items-center justify-content-center home-frame-wraper ">
              <div>
                <img
                  className="home-frames"
                  src={require("../assets/img/home.img/Layer-2.jpg")}
                  alt="home frame 2"
                />
              </div>
              <div className="text-center frame-paras">
                <p>
                  {t("Islandwide")}
                  <br />
                  {t("Service")}
                </p>
              </div>
            </div>
            <div className="col-3 flex align-items-center justify-content-center home-frame-wraper">
              <div>
                <img
                  className="home-frames"
                  src={require("../assets/img/home.img/Layer-3.jpg")}
                  alt="home frame 3"
                />
              </div>
              <div className="text-center frame-paras">
                <p>
                  {t("Consultation")}
                  <br />
                  {t("and Advice")}
                </p>
              </div>
            </div>
            <div className="col-3 flex align-items-center justify-content-center home-frame-wraper">
              <div>
                <img
                  className="home-frames"
                  src={require("../assets/img/home.img/Layer-4.jpg")}
                  alt="home frame 4"
                />
              </div>
              <div className="text-center frame-paras">
                <p>
                  {t("Satisfaction")}
                  <br />
                  {t("Guarantee")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Latest />
      <div className="lg:pt-6 pb-6" style={{ backgroundColor: "#f1e9e3" }}>
        <div className="content-wrapper">
          <div className="grid">
            <div className="col-12 lg:col-7">
              <div className="w-100" style={{ maxWidth: "600px" }}>
                <h3 className="text-4xl">Our Story</h3>
                <p className="text-color">
                  {t("Over a decade ago, we embarked on our entrepreneurial")}
                  {t("journey with the launch of Geeth Curtains near Walpola")}
                  {t("railway station. Throughout these twelve years, we")}
                  &apos;
                  {t(
                    "ve dedicated ourselves to providing honest, cooperative, and"
                  )}
                  {t("amicable service to our valued customers. We")}&apos;
                  {t("ve strived")}
                  {t(
                    "to understand your heartfelt inspirations, wishes, economic"
                  )}
                  {t("constraints, and requirements.")}
                </p>
                <img
                  alt="measure"
                  src={Innovative}
                  loading="lazy"
                  className="innovative-image border-round-md hide-on-desktop mt-2"
                />
                <p className="text-color">
                  {t(
                    "In response to the trust and support bestowed upon us by our"
                  )}
                  {t("thousands of sincere customers, we")}&apos;
                  {t("ve expanded our")}
                  {t(
                    "presence. Today, we proudly announce the opening of our new"
                  )}
                  {t(
                    "showroom opposite Ragama Railway Station. This expansion"
                  )}
                  {t(
                    "marks our commitment to enhancing our service and meeting"
                  )}
                  {t(
                    "your needs more effectively. With three additional branches"
                  )}
                  {t("and a team of skilled staff, we are now known as Geeth")}
                  {t("Curtain Designs, an esteemed and renowned entrepreneur.")}
                </p>
                <p className="text-color">
                  {t("Our journey")}&apos; {t("s success isn")}&apos;
                  {t("t measured by mere")}
                  {t(
                    "achievements but by the enduring affection, cooperation, and"
                  )}
                  {t("trust you")}&apos;
                  {t("ve bestowed upon us. As we look to the")}
                  {t(
                    "future, we are eager to continue providing unparalleled,"
                  )}
                  {t(
                    "friendly service. Your unwavering support inspires us to"
                  )}
                  {t("strive for excellence in everything we do.")}
                </p>
              </div>
              <h3 className="mt-5">GEETH CURTAINS</h3>
              <h5>Innovative Options for the Modern World</h5>
            </div>
            <div className="lg:col-5 hide-on-mobile">
              <img
                alt="measure"
                src={Innovative}
                loading="lazy"
                className="innovative-image border-round-lg"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="pb-6 pt-6">
        <div className="content-wrapper">
          <h2 className="flex justify-content-end text-secondary cormorant-garamond opacity-60">
            {t("Vlogs")} <span className="recent-vlogs-text">Recent</span>
          </h2>
          <div className="grid lg:mt-0 mt-6">
            <div className="lg:col-4 col-12 flex flex-column gap-2 vlog-measure lg:p-4">
              <img
                style={{ marginTop: "10px" }}
                alt="measure"
                src={Measure}
                loading="lazy"
                className="vlog-iamge mb-3 border-round-lg"
              />
              <div className="button-vlog-1">
                <h4>{t("How to Measure")}</h4>
                <Button
                  label={t("Watch Now")}
                  icon="pi pi-angle-right"
                  iconPos="right"
                  className="p-button-outlined p-button-sm btn-outlined w-max"
                  onClick={() =>
                    window.open(
                      "https://www.youtube-nocookie.com/embed/wUpFYBftxr4",
                      "_blank"
                    )
                  }
                />
              </div>
            </div>
            <div className="lg:col-4 col-12 flex lg:flex-column flex-column-reverse gap-2 vlog-website lg:p-4">
              <div className="button-vlog-2">
                <h4>{t("How to Use the Website")}</h4>
                <Button
                  label={t("Watch Now")}
                  icon="pi pi-angle-right"
                  iconPos="right"
                  className="p-button-outlined p-button-sm btn-outlined w-max"
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/embed/paxbnsBuqcg",
                      "_blank"
                    )
                  }
                />
              </div>
              <img
                alt="howto"
                src={HowTo}
                loading="lazy"
                className="vlog-iamge mt-3 border-round-lg"
              />
            </div>
            <div className=" lg:col-4 col-12 flex flex-column gap-2 vlog-quality lg:p-4">
              <img
                alt="quality"
                src={Quality}
                loading="lazy"
                className="vlog-iamge mb-3 border-round-lg"
              />
              <div className="button-vlog-3">
                <h4>{t("Quality of Curtains")}</h4>
                <Button
                  label={t("Watch Now")}
                  icon="pi pi-angle-right"
                  iconPos="right"
                  className="p-button-outlined p-button-sm btn-outlined w-max"
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/embed/8d62oBDLCTI",
                      "_blank"
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="info-wrapper mt-6 lg:mt-8 lg:pb-6">
        <div className="content-wrapper">
          <h2 className="flex justify-content-center text-secondary cormorant-garamond crafted">
            Crafted for <i className="font-semibold luxury">Luxury</i>
          </h2>
          <div className="grid mt-6">
            <div className="lg:col-4">
              <img
                alt="measure"
                src={left}
                loading="lazy"
                className="w-full info-left -mt-5"
              />
            </div>
            <div className="lg:col-4 text-info lg:pb-2 pb-6 px-2">
              <p>
                {t(
                  "We prioritize efficiency and affordability by leveraging modern"
                )}
                {t(
                  "technology in our production processes, ensuring minimized costs"
                )}
                {t(
                  "for you. With a diverse range of curtain designs available, we"
                )}
                {t(
                  "cater to various preferences and budgets. Additionally, our"
                )}
                {t("rapid service guarantees prompt delivery, even for urgent")}
                {t("orders, right to your doorstep.")}
              </p>
              <p>
                {t(
                  "At Geeth Curtain Designs, we maintain extensive material stocks,"
                )}
                {t(
                  "unaffected by prevailing inflation rates. Coupled with years of"
                )}
                {t(
                  "craftsmanship experience, we promise efficient and reliable"
                )}
                {t(
                  "service. Our expertise in modern technology enables us to offer"
                )}
                {t(
                  "tailored designs that perfectly suit your needs and preferences."
                )}
              </p>
              <p>
                {t("Customer satisfaction is our utmost priority. If you")}
                &apos;
                {t(
                  "re ever dissatisfied with our service, we offer a no-deduction"
                )}
                {t(
                  "refund policy. Moreover, our in-house manufacturing of curtain"
                )}
                {t(
                  "poles ensures both aesthetic appeal and durability, enhancing"
                )}
                {t(
                  "the overall look of your curtains. With flexible payment options"
                )}
                {t(
                  "and convenient consultation timings, we strive to make your"
                )}
                {t("experience with us seamless and satisfying.")}
              </p>
            </div>
            <div className="lg:col-4 desktop-specs">
              <img
                alt="measure"
                src={right}
                loading="lazy"
                className="mt-8 border-round-xl"
                style={{
                  width: "23vw",
                  float: "right",
                  aspectRatio: "3/4",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="pt-6 pb-6">
        <div className="content-wrapper mb-0">
          <h1 className="text-center">{t("Meet Us")}</h1>
          <div className="flex justify-content-center align-items-center mt-2 mb-2">
            <span className="icon-border pi pi-phone m-2"></span>
            <span className="text-color cormorant-garamond lg:text-5xl text-2xl m-2">
              {t("Hotline")}
            </span>
            <span className="text-color cormorant-garamond font-semibold lg:text-5xl text-2xl m-2">
              +94 71 802 1976
            </span>
          </div>
          <div className="flex justify-content-center">
            <p className="page-context">
              {t(
                "With our strategically located branches spread across the island we are here to ensure that our service is easily accessible to you wherever you are."
              )}
            </p>
          </div>
        </div>
      </div>
      <div>
        <div className="grid mb-7">
          <div className="col-12 lg:col-3 flex align-items-center justify-content-center">
            <Button
              icon="pi pi-angle-right"
              iconPos="right"
              label="Ragama"
              className="px-0 py-3 text-primary text-xl"
              onClick={() => navigate("/contact-us")}
              text
            />
          </div>
          <div className="col-12 lg:col-3 flex align-items-center justify-content-center">
            <Button
              icon="pi pi-angle-right"
              iconPos="right"
              label="Walpola"
              className="px-0 py-3 text-primary text-xl"
              severity="secondary"
              onClick={() => navigate("/contact-us")}
              text
            />
          </div>
          <div className="col-12 lg:col-3 flex align-items-center justify-content-center">
            <Button
              icon="pi pi-angle-right"
              iconPos="right"
              label="Welimada"
              className="px-0 py-3 text-primary text-xl"
              severity="secondary"
              onClick={() => navigate("/contact-us")}
              text
            />
          </div>
          <div className="col-12 lg:col-3 flex align-items-center justify-content-center">
            <Button
              icon="pi pi-angle-right"
              iconPos="right"
              label="Kurunegala"
              className="px-0 py-3 text-primary text-xl"
              severity="secondary"
              onClick={() => navigate("/contact-us")}
              text
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
