import React, { useState } from "react";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Link, useNavigate } from "react-router-dom";
import "../assets/css/order-status.css";

const OrderTracking = ({ products = [] }) => {
  const [checked, setChecked] = useState(false);
  const [currentStatusIndex, setCurrentStatusIndex] = useState(1);
  const navigate = useNavigate();

  const steps = [
    { icon: "pi-shopping-cart", label: "Confirmed Order" },
    { icon: "pi-cog", label: "Processing Order" },
    { icon: "pi-check-circle", label: "Quality Check" },
    { icon: "pi-car", label: "Product Dispatched" },
    { icon: "pi-home", label: "Product Delivered" },
  ];

  return (
    <div className="order-tracking-container">
      <div className="tracking-header-div">
        <h3 className="tracking-header">Geeth Curtains</h3>
        <h5 className="tracking-sub-header">
          Innovative Options for the Modern World
        </h5>
      </div>

      <div className="flex justify-content-center align-items-center">
        <div className="mb-5 col-12 lg:col-9">
          <div className="detail-header-2">
            <h3>Customer Details</h3>
          </div>
          <div className="">
            <label>Customer Name :</label>
          </div>
          <div className="">
            <label>Telephone / Whatsapp Number :</label>
          </div>
          <div className="">
            <label>Email :</label>
          </div>
          <div className="">
            <label>Address :</label>
          </div>
        </div>
      </div>

      <div>
        <h3 className="tracking-header">TRACKING YOUR ORDER</h3>
        <h3 className="order-no">Order NO - abcy123</h3>
      </div>

      <div className="order-info flex justify-content-between align-items-center p-3 bg-gray-100">
        <span>Shipped Via: </span>
        <span>Status: {steps[currentStatusIndex].label}</span>
        <span>Expected Date: </span>
      </div>

      <div className="tracking-timeline flex align-items-center justify-content-between mt-4 px-4">
        {steps.map((step, index) => (
          <React.Fragment key={index}>
            <div className="timeline-item text-center">
              <div
                className={`timeline-icon ${
                  index <= currentStatusIndex ? "active" : ""
                }`}
              >
                <i className={`pi ${step.icon}`}></i>
              </div>
              <span className="mt-2 block">{step.label}</span>
            </div>
            {index < steps.length - 1 && (
              <div
                className={`timeline-line ${
                  index < currentStatusIndex ? "active" : ""
                }`}
              ></div>
            )}
          </React.Fragment>
        ))}
      </div>

      <div className="flex justify-content-center align-items-center">
        <div className="order-details-status lg:col-9 mb-8">
          <div className="detail-header-2">
            <h3>Order Details</h3>
          </div>
          <div className="">
            <label>Total Quantities</label>
          </div>
          <div className="">
            <label>Pole Width</label>
          </div>
          <div className="">
            <label>Total Pleats</label>
          </div>
          <hr />
          <div className="">
            <label>Fixing Cost</label>
          </div>
          <div className="">
            <label>Transportation Cost</label>
          </div>
          <hr />
          <div className="">
            <span>Total</span>
            <span>LKR </span>
          </div>
        </div>
      </div>

      <div className="main-div-2">
        <div className="flex flex-column">
          <hr />
          <div className="mb-4 flex flex-column align-items-center gap-3">
            <span
              className="cormorant-garamond bg-white lg:px-8 px-4 text-4xl font-medium"
              style={{ color: "#4f6461", marginTop: "-2rem" }}
            >
              Explore More
            </span>
            <div
              className="view-all cursor-pointer text-color"
              onClick={() => navigate("/gallery")}
            >
              View All
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderTracking;
